import { useFinanceEligibilityStore } from '~/stores/forms/financeEligibilityStore'
import { EFinanceProductKeys } from '~/types/finance/product'
import { IQuoteDto, IQuoteError } from '~/types/finance/quote'
import { EFormType } from '~/types/forms'
import { EVehicleType, NewVehicle, UsedVehicle } from '~/types/vehicle'
import { useFormsStore } from '~/stores/forms/formsStore'
import { useOnlineCreditDecisionStore } from '~/stores/forms/ocd/onlineCreditDecisionStore'
import { useVehicleStore } from '~/stores/vehicles/detailsStore'
import { useFinanceQuotesStore } from '~/stores/finance/quotesStore'
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'

export enum OCDRoute {
  Integrated = '1',
  CodeWeavers = '2',
  Notice = '3'
}

export const useOcd = () => {
  const formsStore = useFormsStore()
  const financeQuotesStore = useFinanceQuotesStore()
  const vehiclesDetailsStore = useVehicleStore()
  const ocdStore = useOnlineCreditDecisionStore()
  const financeEligibilityStore = useFinanceEligibilityStore()
  const financeCriteriaStore = useFinanceCriteriaStore()

  const vehicle = computed<NewVehicle | UsedVehicle | null>(
    () => formsStore.getVehicle
  )

  /**
   * Returns true if we have a an OCD Proposal Reference
   */
  const hasProposalReference = computed<boolean>(() => {
    return (
      // todo: do we need this check?
      financeQuotesStore.vin === vehicle.value?.Vin &&
      financeQuotesStore.ocdProposalReference !== null
    )
  })

  /**
   * Returns current Finance Quote
   */
  const financeQuote = computed<IQuoteDto | IQuoteError | undefined>(() => {
    if (financeQuotesStore.vin === vehicle.value?.Vin) {
      return financeQuotesStore.currentQuote?.QuoteDto
    }
  })

  /**
   * Do we need to show notification instead.
   */
  const isNotificationShowing = computed<boolean>(
    () => onlineCreditDecisionRoute.value === OCDRoute.Notice
  )

  /**
   * Check Online Credit Decision is available
   * Where the RetailerApply URL is returned in a financeCalculate response,
   * the apply for finance CTA is expected to be available against the full
   * finance table for Representative Example quotes on new vehicles (it is not for used).
   */
  const isAvailableForFinance = computed<boolean>(() => {
    if (!vehicle.value) return false
    if (!vehicle.value.IsSellable || vehicle.value.Secured) return false
    if (
      !financeCriteriaStore.isPersonalised &&
      vehicle.value?.VehicleType !== EVehicleType.New
    ) {
      return false
    }

    return (
      !isNotificationShowing.value &&
      !!financeQuote.value &&
      !isQuoteError(financeQuote.value) &&
      !!financeQuote.value?.RetailerApply &&
      isOCDAvailable(vehicle.value)
    )
  })

  /**
   * Check Online Credit Decision is available
   * - Must have a personal Finance quote
   * - Must have a Finance quote RetailerApply URL
   */
  const onlineCreditDecisionRoute = computed<OCDRoute>(() => {
    const financeProduct = financeCriteriaStore.currentKey
    const isPartExchange = !!financeQuote.value?.PartExchange || false

    const { PCH, POL, PCP, PHP } = EFinanceProductKeys

    if ([PCH, POL].includes(financeProduct)) {
      return isPartExchange ? OCDRoute.Notice : OCDRoute.Integrated
    }

    if (![PCP, PHP].includes(financeProduct)) {
      return OCDRoute.CodeWeavers
    }

    return OCDRoute.Integrated
  })

  const isOcdSessionActive = computed(() => ocdStore.isSessionActive)
  const { hideSidepanel, showSidepanel } = useSidepanel(ESidepanel.FORMS)

  /**
   * Launch CheckFinance Eligibility
   */
  const checkFinanceEligibility = async (launch = true) => {
    if (onlineCreditDecisionRoute.value === OCDRoute.CodeWeavers) {
      isQuote(financeQuote.value) &&
        financeQuote.value.RetailerApply &&
        window.open(financeQuote.value.RetailerApply)
      hideSidepanel()
      return
    }

    if (onlineCreditDecisionRoute.value === OCDRoute.Integrated) {
      const isCorrectVin = vehicle.value?.Vin === financeQuotesStore.vin

      if (!isCorrectVin || !isOcdSessionActive.value) {
        if (!financeQuote.value) {
          await vehiclesDetailsStore.loadFinance(vehicle.value)
        }

        financeEligibilityStore.resetWithNavigation()
      }

      if (launch) {
        showSidepanel()
        formsStore.updateForm(EFormType.FinanceEligibility)
      }
    }
  }

  return {
    hasProposalReference,
    isAvailableForFinance,
    isNotificationShowing,
    checkFinanceEligibility
  }
}
